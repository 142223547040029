Core.ContentSlider = Garnish.Base.extend(
{
    $elem: null,

    init: function($elem, settings)
    {
        Core.debug && console.log('Core.ContentSlider.init() invoked');

        if (! $elem[0])
        {
            return;
        }

        this.$elem = $elem;

        this.setSettings($.extend(settings, this.$elem.data('plugin-options')), Core.ContentSlider.defaults);

        this.$elem.owlCarousel(this.settings);
    }
},
{
    defaults: {
        items: 3,
        navRewind: false,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        loop: true,
        responsiveClass: true,
        dots: false,
        nav: true,
        stagePadding: 50,
        margin: 10,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2,
            }
        }

        // basic slider settings
        //duration: 7000
    }
});
