Core.MobileMenuToggle = Garnish.Base.extend({

    ClassName: {
        mobileMenuOuterActive: 'mobile-menu-outer--active',
        mobileMenuOpen: 'mobile-menu-is-open',
    },

    Selector: {
        mobileMenuOuter: '.mobile-menu-outer',
        toggleMobileMenu: '.js-toggle-mobile-menu',

    },

    mobileMenuOuter: null,
    toggleMobileMenuTimeout: null,
    resizeTimeout: null,


    init: function(settings) {

        var me = this;

        Core.debug && console.log('Core.MobileMenuToggle.init() invoked');

        me.setSettings(settings, Core.MobileMenuToggle.defaults);

        me.mobileMenuOuter = document.querySelector(me.Selector.mobileMenuOuter);

        if (! me.mobileMenuOuter) {
            return;
        }

        var mobileMenuToggleButtons = Array.from(document.querySelectorAll(me.Selector.toggleMobileMenu));
        mobileMenuToggleButtons.forEach(function(el) {
            me.addListener(el, 'click', 'handleMobileMenuToggleClick');
        });

        //this.addListener(Garnish.$win, 'resize', 'handleWindowResize');
    },

    handleMobileMenuToggleClick: function(e) {

        var me = this;

        if (me.toggleMobileMenuTimeout) {
            return;
        }

        if (document.body.classList.contains(me.ClassName.mobileMenuOpen)) {

            me.toggleMobileMenuTimeout = setTimeout(function () {
                me.toggleMobileMenuTimeout = null;
                me.mobileMenuOuter.classList.remove(me.ClassName.mobileMenuOuterActive);
                me.dispatchMobileMenuClosedEvent();
            }, 500);
        }
        else {
            me.mobileMenuOuter.classList.add(me.ClassName.mobileMenuOuterActive);
        }

        document.body.classList.toggle(me.ClassName.mobileMenuOpen);
    },

    dispatchMobileMenuClosedEvent: function() {

        var me = this;

        var closeMobileMenuEvent = document.createEvent('Event');
        closeMobileMenuEvent.initEvent('closemobilemenu', true, true);
        window.dispatchEvent(closeMobileMenuEvent);
    },

    handleWindowResize: function () {
        var me = this;

        if (me.resizeTimeout) return;

        document.body.classList.remove(me.ClassName.mobileMenuOpen);

        if (me.mobileMenuOuter) {
            me.mobileMenuOuter.classList.remove(me.ClassName.mobileMenuOuterActive);
        }

        if (me.mobileMenuOuter) {
            me.dispatchMobileMenuClosedEvent();
        }

        me.resizeTimeout = setTimeout(function () {
            me.resizeTimeout = null;
        }, 500);
    }


}, {
    defaults: {
    }
});
