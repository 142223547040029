Core.RevolutionSlider = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.RevolutionSlider.init() invoked');

        var $slider = $('.v-rev-slider');

        if (! $slider[0])
        {
            return;
        }

        this.setSettings(settings, Core.RevolutionSlider.defaults);

        var config = $.extend({}, this.settings, $slider.data('slider-options'));

        $slider.each(function ()
        {
            // Initialize Slider
            $(this).show().revolution(config);

        }); // each

    } // init
},
{
    defaults: {

        dottedOverlay: "none",
        delay: 16000,
        startwidth: 1170,
        startheight: 500,
        hideThumbs: 200,

        //hideTimerBar:"on",

        thumbWidth: 100,
        thumbHeight: 50,
        thumbAmount: 5,

        navigationType: "bullet",
        navigationArrows: "solo",
        navigationStyle: "preview4",

        touchenabled: "on",
        onHoverStop: "on",

        swipe_velocity: 0.7,
        swipe_min_touches: 1,
        swipe_max_touches: 1,
        drag_block_vertical: false,

        parallax: "mouse",
        parallaxBgFreeze: "on",
        parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

        keyboardNavigation: "off",

        navigationHAlign: "right",
        navigationVAlign: "bottom",
        navigationHOffset: 105,
        navigationVOffset: 60,

        soloArrowLeftHalign: "left",
        soloArrowLeftValign: "center",
        soloArrowLeftHOffset: 20,
        soloArrowLeftVOffset: 0,

        soloArrowRightHalign: "right",
        soloArrowRightValign: "center",
        soloArrowRightHOffset: 20,
        soloArrowRightVOffset: 0,

        shadow: 0,
        fullWidth: "on",
        fullScreen: "on",

        spinner: "spinner4",

        stopLoop: "off",
        stopAfterLoops: -1,
        stopAtSlide: -1,

        shuffle: "off",

        autoHeight: "off",
        forceFullWidth: "off",

        hideThumbsOnMobile: "off",
        hideNavDelayOnMobile: 1500,
        hideBulletsOnMobile: "off",
        hideArrowsOnMobile: "off",
        hideThumbsUnderResolution: 0,

        hideSliderAtLimit: 0,
        hideCaptionAtLimit: 0,
        hideAllCaptionAtLilmit: 0,
        startWithSlide: 0,
        videoJsPath: "rs-plugin/videojs/",
        fullScreenOffsetContainer: ""
    }
});
