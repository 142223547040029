window.Core = {};
(function($){

/** global: Core */
/** global: Garnish */

$.extend(Core,
    {
        // define containers
        $pageWrapper: $('.ccm-page'),

        headerHeight: 60,

        initialized: false,

        debug: false,

        breakPoints: {
            'sm' : 768,
            'md' : 992,
            'lg' : 1200
        },

        currentBreakPoint: 'xs',

        initialize: function()
        {
            if (this.initialized)
            {
                return;
            }

            this.initialized = true;

            new Core.BreakPoints;
            new Core.StickyHeader({
                'scrollPoints': {
                    'xs': 50,
                    'md': 125
                },
                animate: true
            });
            new Core.HeaderSearch;
            new Core.Clickable;
            new Core.BackToTop({
                'scrollPosition' : 450
            });
            new Core.LanguageSwitch;
            new Core.FlexFill;
            new Core.ColumnEqualHeight;
            new Core.MobileMenuToggle;
            new Core.MobileMenu;

            // Sliders
            new Core.BasicSlider;

            $('.bc-block-content-slider').each(function(){
                new Core.ContentSlider($(this));
            });
        }
    }
);

// -------------------------------------------
//  Custom jQuery plugins
// -------------------------------------------
$.extend($.fn, {

    dummy: function()
    {
        return this.each(function() {
            var $elem = $(this);
        });
    }

});

Garnish.$doc.ready(function()
{
    Core.initialize();
});

Core.BackToTop = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.BackToTop.init() invoked');

        this.setSettings(settings, Core.BackToTop.defaults);

        this.addListener(Garnish.$scrollContainer, 'scroll', 'onScroll');
        this.addListener($('#back-to-top'), 'click', 'scrollToTop');
    },

    scrollToTop: function(e) {
        e.preventDefault();

        $('html,body').animate({
            scrollTop: 0,
        }, this.settings.scrollSpeed);
    },

    onScroll: function(e)
    {
        var $this = $(e.currentTarget),
            scrollPosition = $this.scrollTop();

        if (scrollPosition > this.settings.scrollPosition)
        {
            $('#back-to-top').stop().animate({
                'bottom': '62px',
                'opacity': 1
            }, 300, "easeOutQuart");
        }
        else if (scrollPosition < this.settings.scrollPosition)
        {
            $('#back-to-top').stop().animate({
                'bottom': '-40px',
                'opacity': 0
            }, 300, "easeInQuart");
        }
    }

},
{
    defaults: {
        'scrollPosition': 500
    }
});

Core.BasicSlider = Garnish.Base.extend(
{

    $slider: $('.bc-slider'),
    $elem: null,
    $progressBar: $('<div>', {class:'owl-progressbar'}),
    $bar: $('<div>', {class:'owl-bar'}),
    isPause: false,
    tick: null,
    percentTime: 0,

    init: function(settings)
    {
        Core.debug && console.log('Core.BasicSlider.init() invoked');

        if (! this.$slider[0])
        {
            return;
        }

        var self = this;

        this.setSettings($.extend(settings, this.$slider.data('bc-slider-options')), Core.BasicSlider.defaults);

        this.settings.autoplay = false;
        this.settings.autoplayTimeout = this.settings.duration;

        // Bind caption animation and progressbar to owl carousel.
        this.$slider.on('initialized.owl.carousel', function(event)
        {
            self.addAnim(event);
            self.playVideo();
        });

        var owl = this.$slider.owlCarousel(this.settings);

        owl.on('translate.owl.carousel', function(event) {
            self.addAnim(event);
            self.pauseVideo();
        });

        owl.on('translated.owl.carousel', function(event) {
            self.playVideo();
        });
    },

    pauseVideo: function()
    {
        $('.owl-item video').each(function(){
            $(this).get(0).pause();
        });
    },

    playVideo: function()
    {
        if ($('.owl-item.active video')[0]) {
            $('.owl-item.active video').get(0).play();
        }
    },

    addAnim: function(event)
    {
        if (event.item.count <= 0)
        {
            return;
        }

        var endAnim = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

        var items = this.$slider.find('.owl-item'),
            $currentItem = $(items[event.item.index]),
            $captionHolder = $currentItem.find('.owl-caption-holder'),
            animation = 'animated ' + $captionHolder.data('animation'),
            animationDelay = $captionHolder.data('animation-delay');

        $captionHolder.find('*').css('visibility', 'hidden');
        $captionHolder.find('*').each(function(idx)
        {
            var $self = $(this);
            setTimeout(function()
            {
                $self.css('visibility', 'visible');
                $self.addClass(animation).one(endAnim, function()
                {
                    $(this).removeClass(animation)
                });
            }, (idx + 1) * animationDelay);
        });
    },

    start: function(duration)
    {
        var self = this;
        this.percentTime = 0;
        this.isPause = !1;
        this.tick = setInterval(function(i)
        {
            if (self.isPause === !1)
            {
                self.percentTime += 1e3 / i;
                self.$bar.css({
                    width: self.percentTime + '%'
                });
            }

            if (self.percentTime >= 100)
            {
                self.$slider.trigger('next.owl.carousel');
            }
        }, 10, duration);
    },

    interval: function(i)
    {
        if (this.isPause === !1)
        {
            this.percentTime += 1e3 / i;
            this.$bar.css({
                width: this.percentTime + '%'
            });
        }

        if (this.percentTime >= 100)
        {
            this.$slider.trigger('next.owl.carousel');
        }
    },

    pauseOnDragging: function()
    {
        isPause = !0;
    },

    moved: function(duration)
    {
        clearTimeout(this.tick);
        this.start(duration);
    },

    stopCarouselOnHover: function()
    {
        this.$slider.on('mouseover', function()
        {
            this.isPause = !0;
        });

        this.$slider.on('mouseout', function()
        {
            this.isPause = !1
        });
    }

},
{
    defaults: {
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        items: 1,
        stagePadding: 0,
        smartSpeed: 450,
        margin: 10,
        nav: true,
        dots: true,
        loop: true,
        navRewind: false,
        autoplay: false,
        autoplayTimeout: 7000,
        autoplayHoverPause: true,
        infinite: true,
        responsiveClass: true,

        // basic slider settings
        duration: 7000,
        progressBar: true
    }
});

Core.BreakPoints = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.BreakPoints.init() invoked');

        this.setSettings(settings, Core.BreakPoints.defaults);

        this.addListener(Garnish.$scrollContainer, 'resize', 'updateBreakPoint');

        this.updateBreakPoint();
    },

    updateBreakPoint: function()
    {
        var viewPort = Garnish.$scrollContainer.width(),
            prevBreakPoint = 'xs';

        for (var key in Core.breakPoints)
        {
            if (! Core.breakPoints.hasOwnProperty(key))
            {
                continue;
            }

            var value = Core.breakPoints[key];

            if (viewPort < value)
            {
                Core.currentBreakPoint = prevBreakPoint;
                break;
            }

            prevBreakPoint = key;
            Core.currentBreakPoint = key;
        }

        Core.debug && console.log('Current bootstrap breakpoint:', Core.currentBreakPoint);
    }
},
{
    defaults: {}
});

Core.Clickable = Garnish.Base.extend(
{
    $elem: $('.clickable'),

    init: function(settings)
    {
        Core.debug && console.log('Core.Clickable.init() invoked');

        this.setSettings(settings, Core.Clickable.defaults);

        if (this.$elem[0])
        {
            this.addListener(this.$elem, 'click', 'onClick');
        }
    },

    onClick: function(e)
    {
        var $this = $(e.currentTarget),
            href = $this.data('href');

        window.location.replace(href);
    }
},
{
    defaults: {
    }
});

Core.ColumnEqualHeight = Garnish.Base.extend(
{
    $container: $('.column-equal-height'),

    init: function(settings)
    {
        Core.debug && console.log('Core.ColumnEqualHeight.init() invoked');

        if (! this.$container[0])
        {
            return;
        }

        this.setSettings(settings, Core.ColumnEqualHeight.defaults);

        this.addListener(Garnish.$scrollContainer, 'resize', 'resize');

        this.resize();
    },

    resize: function()
    {
        this.$container.each(function()
        {
            var newHeight = 0,
                $columns = $(this).find('.basic-color-box');

            if (! $columns[0])
            {
                return;
            }

            // reset the column height
            $columns.css('height', 'auto');

            $columns.each(function()
            {
                var columnHeight = $(this).height();

                if (columnHeight > newHeight)
                {
                    newHeight = columnHeight;
                }
            });

            $columns.css('height', newHeight + 'px');
        });
    }

},
{
    defaults: {
    }
});

Core.ContentSlider = Garnish.Base.extend(
{
    $elem: null,

    init: function($elem, settings)
    {
        Core.debug && console.log('Core.ContentSlider.init() invoked');

        if (! $elem[0])
        {
            return;
        }

        this.$elem = $elem;

        this.setSettings($.extend(settings, this.$elem.data('plugin-options')), Core.ContentSlider.defaults);

        this.$elem.owlCarousel(this.settings);
    }
},
{
    defaults: {
        items: 3,
        navRewind: false,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        loop: true,
        responsiveClass: true,
        dots: false,
        nav: true,
        stagePadding: 50,
        margin: 10,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2,
            }
        }

        // basic slider settings
        //duration: 7000
    }
});

Core.FlexFill = Garnish.Base.extend(
{
    $elem: $('.flex-fill'),

    init: function(settings)
    {
        Core.debug && console.log('Core.FlexFill.init() invoked');

        if (! this.$elem[0])
        {
            return;
        }

        this.setSettings(settings, Core.FlexFill.defaults);
        this.addListener(Garnish.$scrollContainer, 'resize', 'resize');

        Garnish.$scrollContainer.trigger('resize');
    },

    resize: function(e)
    {
        var self = this;
        setTimeout(function()
        {
            var $this = $(e.currentTarget);

            self.$elem.removeAttr('style');

            if ($this.width() < 768)
            {
                return;
            }

            self.$elem.each(function()
            {
                // Get the flex container
                var $container = $(this).parents('.flex-row'),
                    height;

                if ($container[0])
                {
                    height = $container.height();
                    $(this).css('height', height + 'px');
                }
            });



        }, 100);

    }

},
{
    defaults: {
    }
});

Core.HeaderSearch = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.HeaderSearch.init() invoked');

        this.setSettings(settings, Core.StickyHeader.defaults);

        var $container = $('#basic-search-header'),
            searchEl = $container.find('.search-input'),
            searchSubmit = searchEl.find('button'),
            $searchForm = $container.find('form');

        Garnish.$doc.on('click', function(e)
        {
            if ($(e.target).closest($container).length === 0)
            {
                searchEl.removeClass('active');

                setTimeout(function()
                {
                    searchEl.hide();
                }, 250);
            }
        });

        // Register button action
        $('#basic-search-header-open').on('click', function(e)
        {
            e.preventDefault();

            searchEl.show();

            setTimeout(function ()
            {
                searchEl.addClass('active');
            }, 50);

            setTimeout(function ()
            {
                searchEl.find('input').focus();
            }, 250);
        });

        // Validate the search input: q is required
        $searchForm.validate({
            rules: {
                q: {
                    required: true
                }
            },
            // errorPlacement: function (error, element)
            // {
            //
            // },
            highlight: function (element) {
                $(element)
                    .closest(".control-group")
                    .removeClass("success")
                    .addClass("error");
            },
            success: function (element) {
                $(element)
                    .closest(".control-group")
                    .removeClass("error")
                    .addClass("success");
            }
        });

        searchSubmit.on("click", function()
        {
            $searchForm.submit();
        });
    }
},
{
    defaults: {}
});

Core.LanguageSwitch = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.LanguageSwitch.init() invoked');

        this.setSettings(settings, Core.LanguageSwitch.defaults);

        this.addListener($(this.settings.languageSwitchMarker), 'change', 'onChange');
    },

    onChange: function(e)
    {
        var $this = $(e.currentTarget),
            selectedValue = $this.val(),
            action = null;

        $this.find('option').each(function()
        {
            var $option = $(this);

            console.log('Option value', $option.text());

            if ($option.text() === selectedValue)
            {
                action = $option.data('action');
            }
        });

        if (action)
        {
            window.location.replace(action);
        }
    }

},
{
    defaults: {
        'languageSwitchMarker': '#basic-language-selector'
    }
});

Core.MasterSlider = Garnish.Base.extend(
{
    init: function(settings)
    {
        var slider = new MasterSlider();
        slider.setup('masterslider', {
            width: 1024,
            height: 580,
            space: 1,
            fullwidth: true,
            centerControls: false,
            //speed: 18,
            loop: true,
            autoplay: true
        });
        //slider.control('arrows');
        slider.control('timebar', { insertTo: '#masterslider' });
        slider.control('bullets', { autohide: false });
    }
},
{
    defaults: {}
});

Core.MobileMenu = Garnish.Base.extend({

    ClassName: {
        subMenuActive: 'sub-menu-active',
        menuActive: 'menu-active',
        sectionHeadingActive: 'mobile-menu__section-header__active'
    },

    Selector: {
        navItem: '.js-contains-sub-menu',
        subMenuButton: '.js-sub-menu-button',
        subMenu: '.js-sub-menu',
        subMenuBackButton: '.js-sub-menu-back-button',
        linkButton: '[data-target]',
        sectionHeader: '.mobile-menu__section-header'
    },

    subMenuAnimateTime: 300,
    navItems: null,


    init: function(settings) {

        var me = this;

        Core.debug && console.log('Core.MobileMenu.init() invoked');

        me.setSettings(settings, Core.MobileMenu.defaults);

        me.navItems = Array.from(document.querySelectorAll(me.Selector.navItem));

        me.navItems.forEach(function(elem, i) {
            var subMenuButton = elem.querySelector(me.Selector.subMenuButton);
            var subMenuContainer = elem.querySelector(me.Selector.subMenu);
            var subMenuBackButton = elem.querySelector(me.Selector.subMenuBackButton);

            if (! subMenuButton || ! subMenuBackButton) {
                console.warn('Element', elem, 'is missing', subMenuButton, me.Selector.subMenu, 'or', me.Selector.subMenuBackButton);
                return;
            }

            subMenuButton.addEventListener('click', function(e) {
                elem.parentNode.parentNode.classList.add(me.ClassName.subMenuActive);
                subMenuContainer.classList.add(me.ClassName.menuActive);
            });

            subMenuBackButton.addEventListener('click', function(e) {
                elem.parentNode.parentNode.classList.remove(me.ClassName.subMenuActive);

                setTimeout(function () {
                    // Allow the animation to complete before disabling the submenu.
                    subMenuContainer.classList.remove(me.ClassName.menuActive);
                }, me.subMenuAnimateTime);
            })
        });

        this.addListener(Garnish.$win, 'closemobilemenu', 'resetMobileMenu');
    },

    resetMobileMenu: function() {

        var me = this;

        me.navItems.forEach(function(elem, i) {
            var subMenuContainer = elem.querySelector(me.Selector.subMenu);
            elem.parentNode.parentNode.classList.remove(me.ClassName.subMenuActive);
            subMenuContainer.classList.remove(me.ClassName.menuActive);
        });
    }

}, {
    defaults: {
    }
});

Core.MobileMenuToggle = Garnish.Base.extend({

    ClassName: {
        mobileMenuOuterActive: 'mobile-menu-outer--active',
        mobileMenuOpen: 'mobile-menu-is-open',
    },

    Selector: {
        mobileMenuOuter: '.mobile-menu-outer',
        toggleMobileMenu: '.js-toggle-mobile-menu',

    },

    mobileMenuOuter: null,
    toggleMobileMenuTimeout: null,
    resizeTimeout: null,


    init: function(settings) {

        var me = this;

        Core.debug && console.log('Core.MobileMenuToggle.init() invoked');

        me.setSettings(settings, Core.MobileMenuToggle.defaults);

        me.mobileMenuOuter = document.querySelector(me.Selector.mobileMenuOuter);

        if (! me.mobileMenuOuter) {
            return;
        }

        var mobileMenuToggleButtons = Array.from(document.querySelectorAll(me.Selector.toggleMobileMenu));
        mobileMenuToggleButtons.forEach(function(el) {
            me.addListener(el, 'click', 'handleMobileMenuToggleClick');
        });

        //this.addListener(Garnish.$win, 'resize', 'handleWindowResize');
    },

    handleMobileMenuToggleClick: function(e) {

        var me = this;

        if (me.toggleMobileMenuTimeout) {
            return;
        }

        if (document.body.classList.contains(me.ClassName.mobileMenuOpen)) {

            me.toggleMobileMenuTimeout = setTimeout(function () {
                me.toggleMobileMenuTimeout = null;
                me.mobileMenuOuter.classList.remove(me.ClassName.mobileMenuOuterActive);
                me.dispatchMobileMenuClosedEvent();
            }, 500);
        }
        else {
            me.mobileMenuOuter.classList.add(me.ClassName.mobileMenuOuterActive);
        }

        document.body.classList.toggle(me.ClassName.mobileMenuOpen);
    },

    dispatchMobileMenuClosedEvent: function() {

        var me = this;

        var closeMobileMenuEvent = document.createEvent('Event');
        closeMobileMenuEvent.initEvent('closemobilemenu', true, true);
        window.dispatchEvent(closeMobileMenuEvent);
    },

    handleWindowResize: function () {
        var me = this;

        if (me.resizeTimeout) return;

        document.body.classList.remove(me.ClassName.mobileMenuOpen);

        if (me.mobileMenuOuter) {
            me.mobileMenuOuter.classList.remove(me.ClassName.mobileMenuOuterActive);
        }

        if (me.mobileMenuOuter) {
            me.dispatchMobileMenuClosedEvent();
        }

        me.resizeTimeout = setTimeout(function () {
            me.resizeTimeout = null;
        }, 500);
    }


}, {
    defaults: {
    }
});

Core.RevolutionSlider = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.RevolutionSlider.init() invoked');

        var $slider = $('.v-rev-slider');

        if (! $slider[0])
        {
            return;
        }

        this.setSettings(settings, Core.RevolutionSlider.defaults);

        var config = $.extend({}, this.settings, $slider.data('slider-options'));

        $slider.each(function ()
        {
            // Initialize Slider
            $(this).show().revolution(config);

        }); // each

    } // init
},
{
    defaults: {

        dottedOverlay: "none",
        delay: 16000,
        startwidth: 1170,
        startheight: 500,
        hideThumbs: 200,

        //hideTimerBar:"on",

        thumbWidth: 100,
        thumbHeight: 50,
        thumbAmount: 5,

        navigationType: "bullet",
        navigationArrows: "solo",
        navigationStyle: "preview4",

        touchenabled: "on",
        onHoverStop: "on",

        swipe_velocity: 0.7,
        swipe_min_touches: 1,
        swipe_max_touches: 1,
        drag_block_vertical: false,

        parallax: "mouse",
        parallaxBgFreeze: "on",
        parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

        keyboardNavigation: "off",

        navigationHAlign: "right",
        navigationVAlign: "bottom",
        navigationHOffset: 105,
        navigationVOffset: 60,

        soloArrowLeftHalign: "left",
        soloArrowLeftValign: "center",
        soloArrowLeftHOffset: 20,
        soloArrowLeftVOffset: 0,

        soloArrowRightHalign: "right",
        soloArrowRightValign: "center",
        soloArrowRightHOffset: 20,
        soloArrowRightVOffset: 0,

        shadow: 0,
        fullWidth: "on",
        fullScreen: "on",

        spinner: "spinner4",

        stopLoop: "off",
        stopAfterLoops: -1,
        stopAtSlide: -1,

        shuffle: "off",

        autoHeight: "off",
        forceFullWidth: "off",

        hideThumbsOnMobile: "off",
        hideNavDelayOnMobile: 1500,
        hideBulletsOnMobile: "off",
        hideArrowsOnMobile: "off",
        hideThumbsUnderResolution: 0,

        hideSliderAtLimit: 0,
        hideCaptionAtLimit: 0,
        hideAllCaptionAtLilmit: 0,
        startWithSlide: 0,
        videoJsPath: "rs-plugin/videojs/",
        fullScreenOffsetContainer: ""
    }
});

Core.SlickSlider = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.SlickSlider.init() invoked');

        var $slider = $('.basic-slider');

        if (! $slider[0])
        {
            return;
        }

        this.setSettings(settings, Core.SlickSlider.defaults);

        var config = $.extend({}, this.settings, $slider.data('slider-options'));

        $slider
            .on('init', function(event, slick)
            {
                var $firstSlide = $(slick.$slides[0]);
                $firstSlide.find('video')[0] && $firstSlide.find('video')[0].play();

                $slider.removeClass('slider-hide');
            })
            .slick(config);

        // On before slide change
        $slider
            .on('beforeChange', function(event, slick, currentSlide, nextSlide)
            {
                var $currentSlide = $(slick.$slides[currentSlide]),
                    $nextSlide = $(slick.$slides[nextSlide]),
                    $video = $nextSlide.find('video');

                // Start playing the video in the slide that is now coming into view
                if ($video[0])
                {
                    $video[0].play();
                }

                // Pause the video in the slide that is going away
                $video = $currentSlide.find('video');
                if ($video[0])
                {
                    $video[0].pause();
                }
            });

    }
},
{
    defaults: {
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnFocus: true,
        pauseOnHover: true,
        duration: 10000
    }
});

Core.StickyHeader = Garnish.Base.extend(
{
    $header: $('.basic-header-fixed'),
    $headerTop: $('.basic-header-top'),
    $headerContainer: $('.basic-header-container'),
    $body: $('body'),
    headerHeight: 0,
    headerTopHeight: 0,

    init: function(settings)
    {
        Core.debug && console.log('Core.StickyHeader.init() invoked');

        if ($('#ccm-toolbar')[0])
        {
            return;
        }

        if (this.$body.hasClass('mobile-menu-open'))
        {
            return;
        }

        if (! this.$header[0])
        {
            return;
        }

        this.setSettings(settings, Core.StickyHeader.defaults);

        this.headerHeight    = this.$header[0] ? this.$header.outerHeight() : 0;
        this.headerTopHeight = this.$headerTop[0] ? this.$headerTop.outerHeight() : 0;

        this.addListener(Garnish.$scrollContainer, 'scroll', 'onScroll');
        this.addListener(Garnish.$scrollContainer, 'resize', 'updateHeader');
    },

    onScroll: function(e)
    {
        if (this.$body.hasClass('mobile-menu-open'))
        {
            return;
        }

        var $this = $(e.currentTarget),
            scrollTop = $this.scrollTop(),
            width = $this.width(),
            scrollPoint = (null !== this.getScrollPoint()) ? this.getScrollPoint() : (this.headerTopHeight + this.headerHeight);

        if (scrollTop > scrollPoint && width > 0)
        {
            Garnish.$bod.addClass('basic-fixed-header-on');

            if (this.settings.animate)
            {
                this.$header.addClass('x-animated x-menu-fadeInDown');
            }

            this.$headerContainer.css('paddingBottom', this.headerHeight + 'px');
        }
        else
        {
            Garnish.$bod.removeClass('basic-fixed-header-on');
            this.$header.removeClass('x-animated x-menu-fadeInDown');
            this.$headerContainer.css('paddingBottom', 0);
        }
    },

    updateHeader: function(e)
    {
        var $this = $(e.currentTarget), scrollPoint;

        if (this.$body.hasClass('mobile-menu-open'))
        {
            return;
        }

        if ($this.width() < 768)
        {
            return;
        }

        scrollPoint = (null !== this.getScrollPoint()) ? this.getScrollPoint() : (this.headerTopHeight + this.headerHeight);
        scrollPoint = scrollPoint - 5;

        this.headerHeight = this.$header[0] ? this.$header.outerHeight() : 0;
        this.headerTopHeight = this.$headerTop[0] ? this.$headerTop.outerHeight() : 0;

        if ($this.scrollTop() < scrollPoint)
        {
            this.headerHeight = this.$header[0] ? this.$header.outerHeight() : 0;
            this.headerTopHeight = this.$headerTop[0] ? this.$headerTop.outerHeight() : 0;
        }
    },

    getScrollPoint: function ()
    {
        var breakPoints = Core.breakPoints,
            scrollPoints = this.settings.scrollPoints,
            currentBreakPoint = Core.currentBreakPoint,
            scrollPoint,
            value = 0;

        if (null === scrollPoints)
        {
            return null;
        }

        for (var breakPoint in breakPoints)
        {
            if (! breakPoints.hasOwnProperty(breakPoint)) continue;

            if (scrollPoints.hasOwnProperty(breakPoint))
            {
                value = scrollPoints[breakPoint];
            }

            scrollPoint = value;

            if (breakPoint === currentBreakPoint)
            {
                break;
            }
        }

        return scrollPoint;
    }

},
{
    defaults: {
        'scrollPoints': null,
        'animate': false
    }
});

})(jQuery);
