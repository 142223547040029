Core.BackToTop = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.BackToTop.init() invoked');

        this.setSettings(settings, Core.BackToTop.defaults);

        this.addListener(Garnish.$scrollContainer, 'scroll', 'onScroll');
        this.addListener($('#back-to-top'), 'click', 'scrollToTop');
    },

    scrollToTop: function(e) {
        e.preventDefault();

        $('html,body').animate({
            scrollTop: 0,
        }, this.settings.scrollSpeed);
    },

    onScroll: function(e)
    {
        var $this = $(e.currentTarget),
            scrollPosition = $this.scrollTop();

        if (scrollPosition > this.settings.scrollPosition)
        {
            $('#back-to-top').stop().animate({
                'bottom': '62px',
                'opacity': 1
            }, 300, "easeOutQuart");
        }
        else if (scrollPosition < this.settings.scrollPosition)
        {
            $('#back-to-top').stop().animate({
                'bottom': '-40px',
                'opacity': 0
            }, 300, "easeInQuart");
        }
    }

},
{
    defaults: {
        'scrollPosition': 500
    }
});
