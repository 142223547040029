Core.BasicSlider = Garnish.Base.extend(
{

    $slider: $('.bc-slider'),
    $elem: null,
    $progressBar: $('<div>', {class:'owl-progressbar'}),
    $bar: $('<div>', {class:'owl-bar'}),
    isPause: false,
    tick: null,
    percentTime: 0,

    init: function(settings)
    {
        Core.debug && console.log('Core.BasicSlider.init() invoked');

        if (! this.$slider[0])
        {
            return;
        }

        var self = this;

        this.setSettings($.extend(settings, this.$slider.data('bc-slider-options')), Core.BasicSlider.defaults);

        this.settings.autoplay = false;
        this.settings.autoplayTimeout = this.settings.duration;

        // Bind caption animation and progressbar to owl carousel.
        this.$slider.on('initialized.owl.carousel', function(event)
        {
            self.addAnim(event);
            self.playVideo();
        });

        var owl = this.$slider.owlCarousel(this.settings);

        owl.on('translate.owl.carousel', function(event) {
            self.addAnim(event);
            self.pauseVideo();
        });

        owl.on('translated.owl.carousel', function(event) {
            self.playVideo();
        });
    },

    pauseVideo: function()
    {
        $('.owl-item video').each(function(){
            $(this).get(0).pause();
        });
    },

    playVideo: function()
    {
        if ($('.owl-item.active video')[0]) {
            $('.owl-item.active video').get(0).play();
        }
    },

    addAnim: function(event)
    {
        if (event.item.count <= 0)
        {
            return;
        }

        var endAnim = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

        var items = this.$slider.find('.owl-item'),
            $currentItem = $(items[event.item.index]),
            $captionHolder = $currentItem.find('.owl-caption-holder'),
            animation = 'animated ' + $captionHolder.data('animation'),
            animationDelay = $captionHolder.data('animation-delay');

        $captionHolder.find('*').css('visibility', 'hidden');
        $captionHolder.find('*').each(function(idx)
        {
            var $self = $(this);
            setTimeout(function()
            {
                $self.css('visibility', 'visible');
                $self.addClass(animation).one(endAnim, function()
                {
                    $(this).removeClass(animation)
                });
            }, (idx + 1) * animationDelay);
        });
    },

    start: function(duration)
    {
        var self = this;
        this.percentTime = 0;
        this.isPause = !1;
        this.tick = setInterval(function(i)
        {
            if (self.isPause === !1)
            {
                self.percentTime += 1e3 / i;
                self.$bar.css({
                    width: self.percentTime + '%'
                });
            }

            if (self.percentTime >= 100)
            {
                self.$slider.trigger('next.owl.carousel');
            }
        }, 10, duration);
    },

    interval: function(i)
    {
        if (this.isPause === !1)
        {
            this.percentTime += 1e3 / i;
            this.$bar.css({
                width: this.percentTime + '%'
            });
        }

        if (this.percentTime >= 100)
        {
            this.$slider.trigger('next.owl.carousel');
        }
    },

    pauseOnDragging: function()
    {
        isPause = !0;
    },

    moved: function(duration)
    {
        clearTimeout(this.tick);
        this.start(duration);
    },

    stopCarouselOnHover: function()
    {
        this.$slider.on('mouseover', function()
        {
            this.isPause = !0;
        });

        this.$slider.on('mouseout', function()
        {
            this.isPause = !1
        });
    }

},
{
    defaults: {
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        items: 1,
        stagePadding: 0,
        smartSpeed: 450,
        margin: 10,
        nav: true,
        dots: true,
        loop: true,
        navRewind: false,
        autoplay: false,
        autoplayTimeout: 7000,
        autoplayHoverPause: true,
        infinite: true,
        responsiveClass: true,

        // basic slider settings
        duration: 7000,
        progressBar: true
    }
});
