Core.MobileMenu = Garnish.Base.extend({

    ClassName: {
        subMenuActive: 'sub-menu-active',
        menuActive: 'menu-active',
        sectionHeadingActive: 'mobile-menu__section-header__active'
    },

    Selector: {
        navItem: '.js-contains-sub-menu',
        subMenuButton: '.js-sub-menu-button',
        subMenu: '.js-sub-menu',
        subMenuBackButton: '.js-sub-menu-back-button',
        linkButton: '[data-target]',
        sectionHeader: '.mobile-menu__section-header'
    },

    subMenuAnimateTime: 300,
    navItems: null,


    init: function(settings) {

        var me = this;

        Core.debug && console.log('Core.MobileMenu.init() invoked');

        me.setSettings(settings, Core.MobileMenu.defaults);

        me.navItems = Array.from(document.querySelectorAll(me.Selector.navItem));

        me.navItems.forEach(function(elem, i) {
            var subMenuButton = elem.querySelector(me.Selector.subMenuButton);
            var subMenuContainer = elem.querySelector(me.Selector.subMenu);
            var subMenuBackButton = elem.querySelector(me.Selector.subMenuBackButton);

            if (! subMenuButton || ! subMenuBackButton) {
                console.warn('Element', elem, 'is missing', subMenuButton, me.Selector.subMenu, 'or', me.Selector.subMenuBackButton);
                return;
            }

            subMenuButton.addEventListener('click', function(e) {
                elem.parentNode.parentNode.classList.add(me.ClassName.subMenuActive);
                subMenuContainer.classList.add(me.ClassName.menuActive);
            });

            subMenuBackButton.addEventListener('click', function(e) {
                elem.parentNode.parentNode.classList.remove(me.ClassName.subMenuActive);

                setTimeout(function () {
                    // Allow the animation to complete before disabling the submenu.
                    subMenuContainer.classList.remove(me.ClassName.menuActive);
                }, me.subMenuAnimateTime);
            })
        });

        this.addListener(Garnish.$win, 'closemobilemenu', 'resetMobileMenu');
    },

    resetMobileMenu: function() {

        var me = this;

        me.navItems.forEach(function(elem, i) {
            var subMenuContainer = elem.querySelector(me.Selector.subMenu);
            elem.parentNode.parentNode.classList.remove(me.ClassName.subMenuActive);
            subMenuContainer.classList.remove(me.ClassName.menuActive);
        });
    }

}, {
    defaults: {
    }
});
